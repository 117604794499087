import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $isElementNode,
  LexicalEditor,
} from 'lexical'
import { useEffect, useRef, useState } from 'react'

import { LexicalComposer } from '@lexical/react/LexicalComposer'

import { CodeNode } from '@lexical/code'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { AutoLinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import DOMPurify from 'dompurify'
import { Maximize2Icon, Minimize2Icon } from 'lucide-react'
import { twMerge } from 'tailwind-merge'
import ToolbarPlugin from './rich-text/toolbar-plugin'

const getInitialState = (editor: any, initialValue: any) => {
  editor.update(() => {
    if (initialValue) {
      // In the browser you can use the native DOMParser API to parse the HTML string.
      const parser = new DOMParser()
      const dom = parser.parseFromString(initialValue, 'text/html')

      // Once you have the DOM instance it's easy to generate LexicalNodes.
      const nodes = $generateNodesFromDOM(editor, dom)

      const root = $getRoot()

      nodes.map((node) => {
        console.log(node, $isElementNode(node))
        if ($isElementNode(node)) {
          root.append(node)
        } else {
          const paragraph = $createParagraphNode()
          const text = $createTextNode(node.getTextContent())
          paragraph.append(text)
          root.append(paragraph)
        }
      })
    } else {
      const root = $getRoot()
      const paragraph = $createParagraphNode()
      const text = $createTextNode('')
      paragraph.append(text)

      root.clear()
      root.append(paragraph)
    }
  })
}
const getEditorConfig = (initialValue: any) => ({
  // The editor theme
  namespace: '',
  theme: {
    text: {
      underline: 'underline',
      strikethrough: 'line-through',
    },
  },
  // Handling of errors during update
  onError(error: any) {
    throw error
  },
  editorState: (editor: any) => getInitialState(editor, initialValue),
  // Any custom nodes go here
  nodes: [
    ListNode,
    ListItemNode,
    HeadingNode,
    QuoteNode,
    AutoLinkNode,
    CodeNode,
  ],
})
// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus()
  }, [editor])

  return null
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
  console.error(error)
}

export function Editor({
  onChange,
  placeholder,
  initialValue,
  className,
}: {
  className?: string
  onChange?: (html: string) => void
  placeholder?: JSX.Element
  initialValue?: string
}) {
  const sanitizeHtml = (html?: string) => {
    if (!html) return ''
    return DOMPurify.sanitize(html, {
      FORBID_TAGS: ['script', 'button'],
    })
  }

  const handleChange = (editorState: any, editor: any) => {
    editor.update(() => {
      const html = $generateHtmlFromNodes(editor)
      onChange?.(html)
    })
  }

  const editorRef = useRef<LexicalEditor | null | undefined>()
  const [toggleHeight, setToggleHeight] = useState(false)

  return (
    <LexicalComposer
      initialConfig={getEditorConfig(sanitizeHtml(initialValue))}
    >
      <div
        className={twMerge(
          'editor-container w-auto overflow-clip rounded-md border border-slate-200 transition-all',
          'focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600'
        )}
        tabIndex={-1}
        onFocus={() => {
          editorRef.current?.focus()
        }}
      >
        <ToolbarPlugin />
        <OnChangePlugin onChange={handleChange} ignoreSelectionChange />
        <div
          className={twMerge(
            'editor-inner prose prose-sm prose-slate relative w-full !max-w-none'
            // className
          )}
          tabIndex={-1}
        >
          <button
            type="button"
            className="absolute right-0 bottom-0 z-[1] h-5 w-5 rounded bg-white p-0.5"
            onClick={() => {
              setToggleHeight(!toggleHeight)
            }}
          >
            {!toggleHeight ? (
              <Maximize2Icon
                className="text-slate-500"
                size={16}
                strokeWidth={1.4}
              />
            ) : (
              <Minimize2Icon
                className="text-slate-500"
                size={16}
                strokeWidth={1.4}
              />
            )}
          </button>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                tabIndex={0}
                className={twMerge(
                  'editor-input h-auto w-full overflow-auto p-2 focus-within:outline-none',
                  className,
                  !toggleHeight ? 'max-h-[200px]' : 'max-h-none'
                )}
              />
            }
            placeholder={() =>
              placeholder ? (
                <p className="pointer-events-none relative !-mt-16 overflow-hidden p-2 pt-0 italic opacity-60">
                  {placeholder}
                </p>
              ) : null
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          {/* <AutoFocusPlugin /> */}
          {/* <MyCustomAutoFocusPlugin /> */}
          <ListPlugin />
          <TabIndentationPlugin />
          <AutoLinkPlugin matchers={MATCHERS} />
          <EditorRefPlugin editorRef={editorRef} />
          {/* {listOnly && <ListenerPlugin />} */}
        </div>
      </div>
    </LexicalComposer>
  )
}

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text)
    if (match === null) {
      return null
    }
    const fullMatch = match[0]
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
      // attributes: { rel: 'noreferrer', target: '_blank' }, // Optional link attributes
    }
  },
]
