import { RoleKey } from '@upper/graphql/auth'
import * as React from 'react'
import { useAuth } from '..'

export function UserHasRoles({
  children,
  roles,
}: {
  children: React.ReactNode
  roles: RoleKey | RoleKey[]
}) {
  const { user, userHasRole } = useAuth()

  if (typeof window === 'undefined') return null

  const hasRequiredRole = userHasRole(roles)

  if (!user) return null
  if (!hasRequiredRole) return null

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
