import { RoleKey } from '@upper/graphql/auth'
import { Button } from '@upper/ui'
import * as React from 'react'
import { useAuth } from '..'

export function WithRoleCheck({
  children,
  roles,
}: {
  children: React.ReactNode
  roles: RoleKey | RoleKey[]
}) {
  const { user, userHasRole, logout } = useAuth()

  if (typeof window === 'undefined') return null

  const hasRequiredRole = userHasRole(roles)

  if (user) {
    if (!hasRequiredRole) {
      return (
        <div className="p-8">
          <p>You don&apos;t have enough permissions to access this app</p>
          <div className="mt-10">
            <Button onClick={logout}>Logout</Button>
          </div>
        </div>
      )
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
